import { Box, Flex, Input, Text, InputProps, Select, SelectProps } from '@chakra-ui/react';
import { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from 'react';
import { ExamModalities, ExamModalityKey, ExamScoreScopeKey, ExamScoreScopes } from './StudentAdmissionParams';
import MainButton from './MainButton';
import { GlobalContext } from '../../../Global.context';
import { useOptimizerApi } from '../../../hooks/useOptimizerApi';
import profileExpertises from '../../profile/expertises';
import useInstitutions from '../../../hooks/useInstitutions';

type StudentAdmissionFormProps = {
  onSuccess: () => void,
};

export const StudentAdmissionForm: FC<StudentAdmissionFormProps> = ({
  onSuccess,
}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [rankingPosition, setRankingPosition] = useState('');
  const [score, setScore] = useState('');
  const [numberOfTries, setNumberOfTries] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [examModality, setExamModality] = useState<ExamModalityKey>('directAccess');
  const [examScoreScope, setExamScoreScope] = useState<ExamScoreScopeKey | undefined>();

  const { data } = useContext(GlobalContext).profile;
  const { studentAdmission } = useOptimizerApi();
  const {
    uf,
    ufs,
    institution,
    shortInstitution,
    institutions,
    handleUf,
    handleInstitution,
  } = useInstitutions('', '', 0);

  const maskPhone = useCallback((e: string) => {
    let input = e.replace(/\D/g, '');

    if (input.length <= 10) {
      input = input.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
    } else {
      input = input.replace(/(\d{2})(\d{1})(\d{4})(\d{0,4})/, '($1) $2 $3-$4');
    }

    setPhone(input.trim());
  }, []);

  const handlePhone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    maskPhone(e.target.value);
  }, [maskPhone]);

  const profile = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }

    setName(data.profile.name);
    if (data.profile.mobilePhone.length !== 0) {
      maskPhone(data.profile.mobilePhone);
    }
    return data.profile;
  }, [data, maskPhone]);

  const checkInt = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    return /^\d*$/.test(e.target.value);
  }, []);

  const checkDecimal = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    return /^\d*\.?\d{0,2}$/.test(e.target.value);
  }, []);

  const handleRankingPosition = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (checkInt(e)) {
      setRankingPosition(e.target.value);
    }
  }, [checkInt]);

  const handleScore = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (checkDecimal(e)) {
      setScore(e.target.value);
    }
  }, [checkDecimal]);

  const handleNumberOfTries = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (checkInt(e)) {
      setNumberOfTries(e.target.value);
    }
  }, [checkInt]);

  const disabled = useMemo(() => {
    if (profile?.email === undefined
        || name.length < 5
        || institution.length === 0
        || phone.length < 14
        || rankingPosition.length === 0
        || score.length === 0) {
      return true;
    }

    if (examModality === 'revalidate') {
      return numberOfTries.length === 0;
    }

    return specialty.length === 0 || examScoreScope === undefined;
  }, [
    examModality, examScoreScope, name.length, numberOfTries,
    phone.length, rankingPosition, score, specialty.length,
    institution.length, profile?.email,
  ]);

  const onSend = useCallback(() => {
    const isRevalidate = examModality === 'revalidate';

    try {
      studentAdmission({
        studentName: name,
        studentEmail: profile?.email || '',
        studentPhone: phone,
        examModality: ExamModalities[examModality].value,
        institutionName: shortInstitution,
        specialtyName: isRevalidate ? undefined : specialty,
        rankingPosition: parseInt(rankingPosition, 10),
        score: parseFloat(score),
        examScoreScope: isRevalidate ? undefined : examScoreScope,
        numberOfTries: isRevalidate ? parseInt(numberOfTries, 10) : undefined,
      });
      onSuccess();
    } catch (e) {
      console.log(e);
    }
  }, [
    profile?.email, examModality, examScoreScope,
    shortInstitution, name, numberOfTries, onSuccess,
    phone, rankingPosition, score, specialty, studentAdmission,
  ]);

  return (
    <Flex flexDir="column">
      <Labeled label="Nome">
        <StyledInput
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Seu nome completo"
        />
      </Labeled>
      <Box h="16px" />
      <Labeled label="Telefone">
        <StyledInput
          value={phone}
          onChange={handlePhone}
          placeholder="Seu telefone"
          maxLength={16}
        />
      </Labeled>
      <Box h="16px" />
      <Labeled label="Modalidade de prova">
        <Flex direction="row">
          <Box flex="1">
            <MainButton
              onClick={() => setExamModality('directAccess')}
              selected={examModality === 'directAccess'}
            >
              {ExamModalities.directAccess.label}
            </MainButton>
          </Box>
          <Box w="8px" />
          <Box flex="1">
            <MainButton
              onClick={() => setExamModality('revalidate')}
              selected={examModality === 'revalidate'}
            >
              {ExamModalities.revalidate.label}
            </MainButton>
          </Box>
          <Box w="8px" />
          <Box flex="1">
            <MainButton
              onClick={() => setExamModality('rPlus')}
              selected={examModality === 'rPlus'}
            >
              {ExamModalities.rPlus.label}
            </MainButton>
          </Box>
        </Flex>
      </Labeled>
      <Box h="16px" />
      <Labeled label="Instituição da prova">
        <Flex direction="row">
          <Box flex="1">
            <StyledSelect
              value={uf}
              onChange={handleUf}
              placeholder="UF"
            >
              {
                ufs.map(e => <option key={e} value={e}>{e}</option>)
              }
            </StyledSelect>
          </Box>
          <Box w="8px" />
          <Box flex="3">
            <StyledSelect
              value={institution}
              onChange={handleInstitution}
              placeholder="Instituição"
            >
              {
                institutions.map(e => <option key={e} value={e}>{e}</option>)
              }
            </StyledSelect>
          </Box>
        </Flex>
      </Labeled>
      {
        (examModality === 'directAccess' || examModality === 'rPlus') && (
          <>
            <Box h="16px" />
            <Labeled label="Especialidade">
              <StyledSelect
                value={specialty}
                onChange={e => setSpecialty(e.target.value)}
                placeholder="Especialidade"
              >
                {
                  profileExpertises.map(e => (
                    <option key={e} value={e}>{e}</option>
                  ))
                }
              </StyledSelect>
            </Labeled>
          </>
        )
      }
      <Box h="16px" />
      <Labeled label="Sua possível colocação">
        <StyledInput
          type="text"
          placeholder="Sua possível colocação"
          value={rankingPosition}
          onChange={handleRankingPosition}
        />
      </Labeled>
      {
        (examModality === 'directAccess' || examModality === 'rPlus') && (
          <>
            <Box h="16px" />
            <Labeled label="Essa colocação é no quadro geral ou na especialidade?">
              <Flex direction="row">
                <Box flex="1">
                  <MainButton
                    onClick={() => setExamScoreScope('overall')}
                    selected={examScoreScope === 'overall'}
                  >
                    {ExamScoreScopes.overall.label}
                  </MainButton>
                </Box>
                <Box w="8px" />
                <Box flex="1">
                  <MainButton
                    onClick={() => setExamScoreScope('specialty')}
                    selected={examScoreScope === 'specialty'}
                  >
                    {ExamScoreScopes.specialty.label}
                  </MainButton>
                </Box>
              </Flex>
            </Labeled>
          </>
        )
      }
      <Box h="16px" />
      <Labeled label="Qual foi a sua nota?">
        <StyledInput
          type="text"
          placeholder="Digite sua nota"
          value={score}
          onChange={handleScore}
        />
      </Labeled>
      {
        examModality === 'revalidate' && (
          <>
            <Box h="16px" />
            <Labeled label="Quantas vezes você já prestou o Revalida?">
              <StyledInput
                type="text"
                placeholder="Digite quantas vezes você já prestou o Revalida"
                value={numberOfTries}
                onChange={handleNumberOfTries}
              />
            </Labeled>
          </>
        )
      }
      <Box h="32px" />
      <MainButton
        fill
        disabled={disabled}
        onClick={onSend}
      >
        Enviar
      </MainButton>
    </Flex>
  );
};

type LabeledProps = {
  label: string,
}

const Labeled: FC<LabeledProps> = ({
  label, children,
}) => {
  return (
    <>
      <Text fontSize="small" color="#2E74DC">{label}</Text>
      <Box height="8px" />
      {children}
    </>
  );
};

const StyledInput: FC<InputProps> = props => {
  return (
    <Input
      h="38px"
      borderRadius="8px"
      style={{
        border: '1px solid #ccc',
      }}
      _focus={{
        borderColor: '#2E74DC',
        boxShadow: '0 0 0 1px #2E74DC',
      }}
      _hover={{
        borderColor: '#2E74DC', // Cor da borda no hover
      }}
      _placeholder={{
        color: '#ccc',
      }}
      bg="white" // Cor de fundo
      color="#4C5567" // Cor do texto
      px="12px" // Padding horizontal
      {...props}
    />
  );
};

const StyledSelect: FC<SelectProps> = props => {
  return (
    <Select
      h="38px"
      borderRadius="8px"
      style={{
        border: '1px solid #ccc',
      }}
      _focus={{
        borderColor: '#2E74DC',
        boxShadow: '0 0 0 1px #2E74DC',
      }}
      _hover={{
        borderColor: '#2E74DC',
      }}
      _placeholder={{
        color: '#ccc',
      }}
      bg="white"
      color="#4C5567"
      {...props}
    />
  );
};
