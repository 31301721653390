import React, {
  ChangeEvent, useState, useMemo, useCallback, Dispatch,
} from 'react';
import { useGetInstitutionList } from '../api/question-institutions';

type UseInstitutions = {
  uf: string,
  institution: string,
  shortInstitution: string,
  complement: string,
  year: number,
  ufs: string[],
  institutions: string[],
  years: number[],
  complements: string[],
  handleUf: ((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void) | undefined,
  handleInstitution: ((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void),
  handleYear: ((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void) | undefined,
  handleComplement: ((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void) | undefined,
  loading: boolean,
  handleClear: () => void,
  template: string,
  setUF: Dispatch<React.SetStateAction<string>>;
};

const useInstitutions = (uf: string, institution: string, year: number, complement?: string,
  isPriorityInstitutionsList?: boolean): UseInstitutions => {
  const [innerUf, setInnerUf] = useState(uf);
  const [innerInstitution, setInnerInstitution] = useState(institution);
  const [innerYear, setInnerYear] = useState(year);
  const [innerComplement, setInnerComplement] = useState(complement ?? '');

  const query = useGetInstitutionList(isPriorityInstitutionsList || false);
  const UFS = useMemo(() => {
    const { data } = query;

    if (data) {
      return data
        .getInstitutionList
        .map(item => item.uf)
        .sort((a, b) => (a < b ? -1 : 1));
    }
    return [];
  }, [query]);

  const institutions = useMemo(() => {
    const { data } = query;
    if (data && innerUf) {
      const find = data.getInstitutionList
        .find(item => item.uf === innerUf);
      if (find) {
        return find
          .institutions
          .map(item => item.name)
          .sort((a, b) => (a < b ? -1 : 1));
      }
    }
    return [];
  }, [innerUf, query]);

  const years = useMemo(() => {
    const { data } = query;
    if (data && innerInstitution && innerUf) {
      const find1 = data.getInstitutionList.find(item => item.uf === innerUf);
      if (find1) {
        const find2 = find1.institutions.find(item => item.name === innerInstitution);
        if (find2) {
          return Array.from(find2.exams.reduce((acc, curr) => acc.add(curr.year), new Set() as Set<number>).values());
        }
      }
    }
    return [];
  }, [innerInstitution, innerUf, query]);

  const complements = useMemo(() => {
    const { data } = query;
    if (data && innerInstitution && innerUf && innerYear) {
      const findUf = data.getInstitutionList.find(item => item.uf === innerUf);
      if (findUf) {
        const findInstitutions = findUf.institutions.find(item => item.name === innerInstitution);
        if (findInstitutions) {
          const findYear = findInstitutions.exams.filter(item => item.year === innerYear);
          if (findYear.length > 1) {
            return findYear.map(item => item.complement ?? '').filter(Boolean);
          }
        }
      }
    }
    return [];
  }, [innerInstitution, innerUf, innerYear, query]);

  const template = useMemo(() => {
    const { data } = query;

    if (data && innerInstitution && innerUf && innerYear) {
      const findUf = data.getInstitutionList.find(item => item.uf === innerUf);

      if (findUf) {
        const findInstitution = findUf.institutions.find(item => item.name === innerInstitution);
        if (findInstitution) {
          const findYear = findInstitution.exams.filter(item => item.year === innerYear);
          if (findYear.length === 1) {
            return findYear[0].template;
          }
          if (findYear.length > 1) {
            const findComplement = findYear.find(item => item.complement === innerComplement);
            if (findComplement) {
              return findComplement.template;
            }
            return findYear[0].template;
          }
        }
      }
    }
    return '';
  }, [innerComplement, innerInstitution, innerUf, innerYear, query]);

  const handleUf = useCallback(e => {
    setInnerUf(e.target.value);
  }, []);

  const handleInstitution = useCallback(e => {
    setInnerInstitution(e.target.value);
  }, []);

  const handleYear = useCallback(e => {
    setInnerYear(Number(e.target.value));
  }, []);

  const handleComplement = useCallback(e => {
    setInnerComplement(e.target.value);
  }, []);

  const handleClear = useCallback(() => {
    setInnerUf('');
    setInnerInstitution('');
    setInnerYear(0);
    setInnerComplement('');
  }, []);

  const shortInstitution = useMemo(() => {
    if (!innerInstitution) {
      return '';
    }

    const match = /\(([^)]+)\)/.exec(innerInstitution);
    if (!match || !match[1]) {
      return '';
    }

    return match[1];
  }, [innerInstitution]);

  return {
    uf: innerUf,
    institution: innerInstitution,
    shortInstitution,
    year: innerYear,
    complement: innerComplement,
    ufs: UFS,
    setUF: setInnerUf,
    institutions,
    years,
    complements,
    handleUf,
    handleInstitution,
    handleYear,
    handleComplement,
    loading: query.loading,
    handleClear,
    template,
  };
};

export default useInstitutions;
