import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { HeadModal } from './HeadModal';
import MainButton from './MainButton';

type DeclinedModalProps = {
  onClose: () => void,
};

export const DeclinedModal: FC<DeclinedModalProps> = ({
  onClose,
}) => {
  return (
    <HeadModal title="Você já sabe o resultado da sua prova?" width={321}>
      <Text color="#4C5567">
        Sem problemas! Quando souber o resultado, volte aqui para nos contar e ajudar nossos alunos!
      </Text>
      <Box h="8px" />
      <Text color="#4C5567">
        Você pode nos contar o resultado mais tarde clicando no ícone abaixo.
      </Text>
      <Box h="12px" />
      <MainButton onClick={onClose}>Ok, entendi</MainButton>
    </HeadModal>
  );
};
