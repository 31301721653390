import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { HeadModal } from './HeadModal';
import { StudentAdmissionForm } from './StudentAdmissionForm';

type StudentFormModalProps = {
  onClose: () => void,
  onSuccess: () => void,
}

export const StudentFormModal: FC<StudentFormModalProps> = ({
  onClose, onSuccess,
}) => {
  return (
    <HeadModal
      title="Preencha as informações sobre o resultado da sua prova"
      onClose={onClose}
      width={390}
    >
      <Box>
        <StudentAdmissionForm onSuccess={onSuccess} />
      </Box>
    </HeadModal>
  );
};
