import { FC } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import Text from '../../../lib/components/Text';

type HeadModalProps = {
  title: string,
  width: number,
  onClose?: () => void,
}

export const HeadModal: FC<HeadModalProps> = ({
  width, title, onClose, children,
}) => {
  return (
    <Box
      width={width}
      borderRadius={16}
      overflow="hidden"
      backgroundColor="#fff"
      boxShadow="lg"
    >
      <Flex p={4} bg="#041223" color="#89B2F9">
        <Box flex="1">
          <Text bold>{title}</Text>
        </Box>
        {
          onClose !== undefined && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              bg="transparent"
              icon={<FontAwesomeIcon icon={faTimes} color="#89B2F9" />}
            />
          )
        }
      </Flex>
      <Box padding={4} flex="1 0 auto" bg="#fff">
        {children}
      </Box>
    </Box>
  );
};
