export interface StudentAdmissionParams {
  studentName: string,
  studentEmail: string,
  studentPhone: string,
  examModality: ExamModalityValue,
  institutionName: string,
  specialtyName?: string,
  rankingPosition: number,
  examScoreScope?: ExamScoreScopeValue,
  score: number,
  numberOfTries?: number,
}

type ExamModalityValue = 'directAccess' | 'revalidate' | 'r+';
type ExamScoreScopeValue = 'overall' | 'specialty';

export const ExamScoreScopes = {
  overall: { value: 'overall', label: 'Quadro geral' },
  specialty: { value: 'specialty', label: 'Especialidade' },
} as const;

// Tipo para restringir as chaves do objeto
export type ExamScoreScopeKey = keyof typeof ExamScoreScopes;

export const ExamModalities = {
  directAccess: { value: 'directAccess', label: 'Acesso direto' },
  revalidate: { value: 'revalidate', label: 'Revalida' },
  rPlus: { value: 'r+', label: 'R+' },
} as const;

// Tipo para restringir as chaves do objeto
export type ExamModalityKey = keyof typeof ExamModalities;
