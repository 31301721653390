import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { HeadModal } from './HeadModal';
import MainButton from './MainButton';

type ExamReceivedProps = {
  onAccept: () => void;
  onDecline: () => void;
}

export const ExamReceivedModal: FC<ExamReceivedProps> = ({
  onAccept, onDecline,
}) => {
  return (
    <HeadModal
      width={244}
      title="Você já recebeu algum resultado da sua prova?"
    >
      <Flex flexDir="column">
        <MainButton onClick={onAccept}>Sim</MainButton>
        <Box h="8px" />
        <MainButton onClick={onDecline}>Ainda não</MainButton>
      </Flex>
    </HeadModal>
  );
};
