import React, { useMemo } from 'react';
import { Button } from '@chakra-ui/react';

interface MainButtonProps {
  onClick: () => void;
  fill?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

const MainButton: React.FC<MainButtonProps> = ({
  onClick,
  fill = false,
  selected,
  disabled = false,
  children,
}) => {
  // Cores do botão
  const primary = '#2E74DC';
  const onPrimary = 'white';
  const defaultColor = '#4C5567';
  const outline = '#CCCCCC';

  // Determinação das cores
  const color = useMemo(() => {
    if (selected === false) {
      return defaultColor;
    }
    return fill ? onPrimary : primary;
  }, [fill, selected]);

  const bgColor = useMemo(() => {
    if (selected === true) {
      return `${primary}1A`;
    }
    return fill
      ? primary
      : onPrimary;
  }, [fill, selected]);

  // Determinação da borda
  const border = useMemo(() => {
    if (selected === false) {
      return `1px solid ${outline}`;
    }
    return fill
      ? undefined
      : `1px solid ${primary}`;
  }, [fill, selected]);

  const hoverBg = useMemo(() => {
    if (disabled) {
      return bgColor;
    }
    return fill ? primary : `${primary}1A`;
  }, [bgColor, disabled, fill]);

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      h="40px"
      w="full"
      borderRadius="8px"
      bg={bgColor}
      color={color}
      border={border}
      fontWeight={selected === false ? 'normal' : 'bold'}
      opacity={disabled ? 0.5 : 1}
      _hover={{ bg: hoverBg }}
    >
      {children}
    </Button>
  );
};

export default MainButton;
