import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { HeadModal } from './HeadModal';
import MainButton from './MainButton';

type ThanksModalProps = {
  onClose: () => void,
}

export const ThanksModal: FC<ThanksModalProps> = ({
  onClose,
}) => {
  return (
    <HeadModal title="Obrigado por compartilhar!" width={296}>
      <Flex flexDir="column">
        <Text color="#041223" fontSize="small">
          Suas informações ajudam a melhorar nossa plataforma e a apoiar outros alunos no caminho para o sucesso.
        </Text>
        <Box h="16px" />
        <MainButton fill onClick={onClose}>Fechar</MainButton>
      </Flex>
    </HeadModal>
  );
};
